import React, { useEffect, useState } from "react";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
} from "@mui/material/";
function Filters({
	filters,
	filtersSelected,
	isCheckAllFilters,
	setFiltersSelected,
	setIsCheckAllFilters,
	showClearAllButton,
	filtersClearAll,
}) {
	const [screenWidth, setScreenWidth] = useState();
	const [userSubmittedNewFilters, setUserSubmittedNewFilters] =
		useState(false);
	const filtersHandleChange = (e, filterName) => {
		e.preventDefault();
		if (e.target.value === filtersSelected[filterName]) {
			setUserSubmittedNewFilters(false);
		} else {
			setUserSubmittedNewFilters(true);
			if (e.target.value.includes("Select All")) {
				const filtersSelectedTmp = { ...filtersSelected };
				if (isCheckAllFilters[filterName]) {
					filtersSelectedTmp[filterName] = [];
				} else {
					filtersSelectedTmp[filterName] = filters[filterName];
				}

				setFiltersSelected(filtersSelectedTmp);
				const isCheckAllFiltersTmp = {
					...isCheckAllFilters,
				};
				isCheckAllFiltersTmp[filterName] =
					!isCheckAllFiltersTmp[filterName];
				setIsCheckAllFilters(isCheckAllFiltersTmp);
			} else {
				const isCheckAllFiltersTmp = {
					...isCheckAllFilters,
				};
				isCheckAllFiltersTmp[filterName] = false;
				setIsCheckAllFilters(isCheckAllFiltersTmp);

				const filtersSelectedTmp = { ...filtersSelected };
				filtersSelectedTmp[filterName] = e.target.value;
				setFiltersSelected(filtersSelectedTmp);
			}
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			{screenWidth && (
				<div className="filters-container">
					{Object.keys(filters).map((filterName, index) => (
						<React.Fragment key={filterName}>
							{index === 3 &&
								showClearAllButton &&
								screenWidth > 1000 && (
									<button
										type="button"
										id="filters_clear_button"
										onClick={filtersClearAll}
									>
										Clear all filters
									</button>
								)}

							<FormControl
								id={filterName + "-filter-container"}
								size="small"
								className="filter"
								key={filterName + "-filter-container"}
							>
								<InputLabel
									id={filterName + "-filter-label"}
									style={{
										width: "210px !important",
										paddingRight: "0.8% !important",
									}}
								>
									{filterName === "Biofield Modality"
										? "Intervention (Biofield Modality)"
										: filterName === "Condition Category"
										? "Health Condition"
										: filterName === "Direction of Results"
										? "Direction of Study Results"
										: filterName === "Study design subtype"
										? "Study Design Subtype"
										: filterName === "Country conducted"
										? "Country Conducted"
										: filterName}
								</InputLabel>
								<Select
									MenuProps={{ autoFocus: false }}
									labelId={
										filterName + "-multiple-checkbox-label"
									}
									id={filterName + "-multiple-checkbox"}
									multiple
									value={filtersSelected[filterName]}
									onChange={(e) =>
										filtersHandleChange(e, filterName)
									}
									onClose={() => {
										if (userSubmittedNewFilters) {
											// filtersSendData();
											setUserSubmittedNewFilters(false);
										}
									}}
									label={
										filterName === "Biofield Modality"
											? "Intervention (Biofield Modality)"
											: filterName ===
											  "Condition Category"
											? "Health Condition"
											: filterName ===
											  "Direction of Results"
											? "Direction of Study Results"
											: filterName ===
											  "Study design subtype"
											? "Study Design Subtype"
											: filterName === "Country conducted"
											? "Country Conducted"
											: filterName
									}
									renderValue={(selected) => {
										selected = selected.filter((n) => n);
										return selected.join(", ");
									}}
								>
									<MenuItem
										key="Select All"
										value="Select All"
									>
										<Checkbox
											checked={
												isCheckAllFilters[filterName]
											}
										/>
										<ListItemText primary="Select All" />
									</MenuItem>
									{filters[filterName].map((sampleOption) => {
										return (
											<MenuItem
												key={sampleOption}
												value={sampleOption}
											>
												<Checkbox
													checked={
														filtersSelected[
															filterName
														].indexOf(
															sampleOption
														) > -1
													}
												/>
												<ListItemText
													primary={sampleOption}
												/>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</React.Fragment>
					))}
					{showClearAllButton ? (
						<>
							{/* Just an extra button to fill the space: */}
							<div
								id="ghost_container"
								style={{ visibility: "hidden" }}
							/>
							{screenWidth <= 1000 && (
								<button
									type="button"
									id="filters_clear_button"
									onClick={filtersClearAll}
								>
									Clear all filters
								</button>
							)}
						</>
					) : (
						<div className="filter" />
					)}
				</div>
			)}
		</>
	);
}

export default Filters;
