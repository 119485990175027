import { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header.jsx";
import LoadingSpinner from "../components/LoadingSpinner.jsx";
import EmailInputModal from "../components/EmailInputModal.jsx";
import ClientLogo from "../images/client_logo.svg";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

function Report() {
	const { reportId } = useParams();
	const [report, setReport] = useState(null);
	const [reportURL, setReportURL] = useState(null);
	const [similarReports, setSimilarReports] = useState(null);
	const [additionalInformation, setAdditionalInformation] = useState(null);
	const [citations, setCitations] = useState(null);
	const [showEmailModal, setShowEmailModal] = useState(null);
	const [verifyCheck, setVerifyCheck] = useState(null);
	const { state } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [state]);
	useEffect(() => {
		document.body.classList.remove("modal-open");
	}, []);
	function mergeReports(reports) {
		const mergedReport = {};
		reports.forEach((report) => {
			Object.keys(report).forEach((reportKey) => {
				if (!mergedReport.hasOwnProperty(reportKey)) {
					mergedReport[reportKey] = "";
				}
				if (!mergedReport[reportKey].includes(report[reportKey])) {
					if (mergedReport[reportKey] !== "") {
						mergedReport[reportKey] += "; ";
					}
					mergedReport[reportKey] += report[reportKey];
				}
			});
		});
		return mergedReport;
	}

	useEffect(() => {
		const otherReports = state !== null ? state.otherReports : null;
		axios({
			method: "POST",
			url: "/get_report_data/",
			data: { reportId },
		})
			.then((response) => {
				const currentReport = mergeReports(response.data);
				setReport(currentReport);
				if (currentReport.pubmed_link !== "Not Reported") {
					if (
						currentReport.pubmed_link.includes("https://") ||
						currentReport.pubmed_link.includes("http://")
					) {
						setReportURL(currentReport.pubmed_link);
					} else {
						setReportURL(`https://${currentReport.pubmed_link}`);
					}
				} else {
					if (currentReport.publisher_link !== "Not Reported") {
						if (
							currentReport.publisher_link.includes("https://") ||
							currentReport.publisher_link.includes("http://")
						) {
							setReportURL(currentReport.publisher_link);
						} else {
							setReportURL(
								`https://${currentReport.publisher_link}`
							);
						}
					}
				}
				setCitations({ Citations: currentReport.citation });
				setAdditionalInformation({
					Study_Design: currentReport.study_design_category,
					Country_Conducted: currentReport.country,
					Species: currentReport.species,
					Sample_Size: currentReport.sample_size_int,
					Condition: currentReport.condition,
					Biofield_Intervention: currentReport.intervention,
					"Author’s Intervention Description":
						currentReport.intervention_description,
					"Proximity to Patient/Participant (Distance or Local)":
						currentReport.healing,
					Dosing: {
						"Number of Biofield Sessions":
							currentReport.number_of_sessions_article,
						"Length of Each Biofield Session (in minutes)":
							currentReport.length_of_sessions,
						"Over how many weeks": currentReport.how_many_weeks,
					},
					"Number of healers involved in the study":
						currentReport.number_of_healers,
					"Comparator(s)": currentReport.comparator_name,
					Outcomes: currentReport.outcomes,
					"Reporting on Mechanism": currentReport.testing_mechanism,
					"Direction of Results as Reported by Abstract":
						currentReport.direction_of_results.replace(/;/g, ""),
				});
				if (otherReports) {
					setSimilarReports(otherReports);
				}
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
	}, [reportId, state]);

	const handleProceedWithEmail = (email, name, surname, userType) => {
		axios({
			method: "POST",
			url: "/save_user_data/",
			data: {
				name: name,
				surname: surname,
				email: email,
				userType: userType,
			},
		})
			.then((response) => {
				localStorage.setItem("verified", true);

				setVerifyCheck(true);
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
				}
			});
	};

	useEffect(() => {
		// Retrieve user email from sessionStorage when the component mounts
		const verified = localStorage.getItem("verified");
		if (verified === "true") {
			setShowEmailModal(false);
		} else setShowEmailModal(true);
	}, [verifyCheck]);
	return (
		<>
			<Header />

			{showEmailModal ? (
				<EmailInputModal
					isOpen={showEmailModal}
					onProceed={handleProceedWithEmail}
				/>
			) : (
				<div className="container " id="report">
					{report && additionalInformation ? (
						<div className="row mt-0">
							<div
								id="reports_info"
								className={
									similarReports
										? " col-12 col-lg-9"
										: " col-12"
								}
							>
								<h3
									className="reports-report-title"
									style={{ color: "#2b2b2b !important" }}
								>
									{report.title + " (" + report.year + ")"}
								</h3>
								<p
									className="reports-citation mt-3"
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									{citations.Citations.split(";").map(
										(citation, index) => (
											<span key={index}>
												{citation}
												<br />
											</span>
										)
									)}
									{reportURL ? (
										<a
											id="report-link"
											target="_blank"
											rel="noreferrer"
											href={reportURL}
										>
											View publication here
										</a>
									) : (
										<span>No publication found.</span>
									)}
								</p>

								{/* Reports - abstract */}
								<>
									<h5 className="reports-abstract-label">
										Abstract
									</h5>
									<p className="abstract">
										{report.abstract}
									</p>
								</>
								{additionalInformation && (
									<ul style={{ wordWrap: "break-word" }}>
										{Object.entries(
											additionalInformation
										).map(
											([key, value]) =>
												value &&
												value !== null && (
													<li key={key}>
														<b>
															{key.replace(
																/_/g,
																" "
															)}
															:
														</b>{" "}
														{key.includes(
															"description"
														) && <br />}
														{key === "Dosing" ? (
															<ul>
																{Object.entries(
																	value
																).map(
																	([
																		subKey,
																		subValue,
																	]) => {
																		return (
																			<li
																				key={
																					subKey
																				}
																			>
																				<b>
																					{
																						subKey
																					}

																					:
																				</b>{" "}
																				{subValue.includes(
																					";"
																				) ? (
																					<ul>
																						{subValue
																							.split(
																								";"
																							)
																							.map(
																								(
																									subsubValue,
																									index
																								) =>
																									subsubValue.trim() !==
																									"" ? (
																										<li
																											key={
																												index
																											}
																										>
																											{
																												subsubValue
																											}
																										</li>
																									) : null
																							)}
																					</ul>
																				) : (
																					subValue
																				)}
																			</li>
																		);
																	}
																)}
															</ul>
														) : key ===
																"Study_Design" ||
														  key === "Species" ||
														  key ===
																"Proximity to Patient/Participant (Distance or Local)" ||
														  key ===
																"Reporting on Mechanism" ||
														  key ===
																"Author’s Intervention Description" ? (
															// if value string starts and ends with ;
															// remove them else keep them
															/^;|;$/.test(
																value
															) ? (
																value.replace(
																	/^;|;$/g,
																	" "
																)
															) : (
																value
															)
														) : value !== null &&
														  value !==
																undefined ? (
															value.includes(
																";"
															) ? (
																<ul>
																	{value
																		.split(
																			";"
																		)
																		.map(
																			(
																				subValue,
																				index
																			) =>
																				subValue.trim() !==
																				"" ? (
																					<li
																						key={
																							index
																						}
																					>
																						{
																							subValue
																						}
																					</li>
																				) : null
																		)}
																</ul>
															) : !value.startsWith(
																	"http"
															  ) ? (
																" " + value
															) : (
																<a
																	target="_blank"
																	rel="noreferrer"
																	href={value}
																>
																	{value}
																</a>
															)
														) : null}
													</li>
												)
										)}
									</ul>
								)}
							</div>

							{similarReports && (
								<div
									id="reports_sidebar"
									className="col-12 col-lg-3 mt-4 mt-lg-0"
									// style={{ height: "100vh" }}
								>
									{/* Reports - Other reports sidebar title */}
									<h3 className="similar-reports-label">
										{"Other reports in this category (" +
											(similarReports.length - 1) +
											")"}
									</h3>
									<hr id="divider" />
									<ul className="similar-reports-list col-12 overflow-auto">
										{similarReports
											.filter(
												(otherReport) =>
													parseInt(
														otherReport.covidence
													) !==
													parseInt(report.covidence)
											)
											.map((otherReport) => {
												return (
													<div
														key={
															otherReport.covidence
														}
													>
														<Link
															to={{
																pathname:
																	"/report/" +
																	otherReport.covidence,
															}}
															state={{
																otherReports:
																	similarReports,
															}}
															id={
																otherReport.covidence
															}
															className="report-links"
														>
															<li>
																{otherReport.title +
																	" (" +
																	otherReport.year +
																	")"}
															</li>
														</Link>
														<hr id="divider" />
													</div>
												);
											})}
									</ul>
								</div>
							)}

							<div className="report-bottom">
								<a
									href="/"
									className="btn btn-primary col-12 col-sm-12 col-md-4 mt-4"
									id="back_to_map_button"
									style={{ marginTop: 25 }}
								>
									Back
								</a>
								<div
									className="bottom-client-logo-container"
									style={{
										display: "flex",
										flexDirection: "column",
										marginTop: 25,
										marginRight: -300,
									}}
								>
									<p className="upload-excel-container logo-description">
										The Biofield Science Evidence Map was
										made possible{" "}
										<br id="description-break" />
										through funding support from members of:
									</p>
									<a
										href="/"
										style={{
											display: "flex",
											width: "200px",
											height: "60px",
											marginLeft: "auto",
										}}
									>
										<img
											id="client_logo"
											src={ClientLogo}
											alt="ClientLogo"
											className="img-fluid"
										/>
									</a>
								</div>
							</div>
						</div>
					) : (
						<LoadingSpinner />
					)}
				</div>
			)}
		</>
	);
}

export default Report;
