import React, { useEffect, useState } from "react";
import "../css/ReportsTable.css";
import { getBubblePerimeter } from "../functions/helperFunctions";
import { Typography } from "@mui/material/";

function ReportsTable({
	xAxisLabels,
	yAxisLabels,
	xAxisTitle,
	yAxisTitle,
	cleanData,
	handleShowReports,
	papersResult,
	toggleHorizontalScrolling,
}) {
	const maxNumOfReports = Math.max(
		...Object.values(cleanData).flatMap((obj) =>
			Object.values(obj).map((arr) => arr.titles.length)
		)
	);
	const maxNumOfColumns = xAxisLabels.length + 1; //xAxisLabels + 1 for the Total column

	const [chunkSize, setChunkSize] = useState(maxNumOfColumns);
	const [chunkedXAxisLabels, setChunkedXAxisLabels] = useState();
	const [mapVisibility, setMapVisibility] = useState(null);
	const [xHeadingWidth, setXheadingWidth] = useState();
	const calculateRowSum = (xChunks, yAxisLabel) => {
		let uniqueIds = new Set();

		xChunks.forEach((xchunk) => {
			const ids = cleanData[xchunk][yAxisLabel]["ids"];
			ids.forEach((id) => {
				uniqueIds.add(id);
			});
		});

		return uniqueIds.size;
	};
	const calculateColumnSum = (xaxis) => {
		let uniqueColIds = new Set();
		yAxisLabels.forEach((yAxisLabel) => {
			const ids = cleanData[xaxis][yAxisLabel]["ids"];
			ids.forEach((id) => {
				uniqueColIds.add(id);
			});
		});
		return uniqueColIds.size;
	};
	const calculateChunkSize = () => {
		const container = document.getElementById("table-container");
		if (container) {
			if (toggleHorizontalScrolling === false) {
				const containerWidth = container.clientWidth;
				let newSize;
				if (screenSize >= 768) {
					newSize = 3;
				} else if (containerWidth === 530) {
					newSize = 2;
				} else {
					newSize = 1;
				}

				newSize < 1 && (newSize = 1);
				setChunkSize(newSize);
				container.classList.remove("horizontal");
				const table = container.querySelector("table");
				if (table) {
					const thead = table.querySelector("thead");
					table.classList.remove("horizontal-table");
					if (thead) {
						thead.classList.remove("horizontal-thead");
						const xHeadingTh = thead.querySelector(
							"th.evidence-map-x-heading"
						);
						const yHeadingTh = thead.querySelector(
							"th.evidence-map-y-heading"
						);
						const whiteTopLeft = thead.querySelector(
							"td.evidence-map-top-left-white"
						);
						if (whiteTopLeft) {
							whiteTopLeft.classList.remove(
								"horizontal-top-left-white"
							);
						}
						if (xHeadingTh && yHeadingTh) {
							xHeadingTh.classList.remove("horizontal-x-heading");
							yHeadingTh.classList.remove("horizontal-y-heading");
						}
						const tbody = table.querySelector("tbody");
						if (tbody) {
							const yHeadingTds = tbody.querySelectorAll(
								"td.evidence-map-y-heading-categories"
							);
							yHeadingTds.forEach((td) => {
								td.classList.remove(
									"horizontal-y-heading-categories"
								);
							});
						}
					}
				}
			} else {
				setChunkSize(maxNumOfColumns);
				container.classList.add("horizontal");
				const table = container.querySelector("table");
				if (table) {
					const thead = table.querySelector("thead");
					table.classList.add("horizontal-table");
					if (thead) {
						thead.classList.add("horizontal-thead");
						const xHeadingTh = thead.querySelector(
							"th.evidence-map-x-heading"
						);
						const yHeadingTh = thead.querySelector(
							"th.evidence-map-y-heading"
						);
						const whiteTopLeft = thead.querySelector(
							"td.evidence-map-top-left-white"
						);
						if (whiteTopLeft) {
							whiteTopLeft.classList.add(
								"horizontal-top-left-white"
							);
						}
						if (xHeadingTh && yHeadingTh) {
							xHeadingTh.classList.add("horizontal-x-heading");
							yHeadingTh.classList.add("horizontal-y-heading");
						}
						const tbody = table.querySelector("tbody");
						if (tbody) {
							const yHeadingTds = tbody.querySelectorAll(
								"td.evidence-map-y-heading-categories"
							);
							yHeadingTds.forEach((td) => {
								td.classList.add(
									"horizontal-y-heading-categories"
								);
							});
						}
					}
				}
			}
		}
	};
	// const calculateChunkSize = () => {
	// 	const container = document.getElementById("table-container");
	// 	if (container) {
	// 		if (window.innerWidth < 768) {
	// 			// if we are in mobile, small screens
	// 			const containerWidth = container.clientWidth;
	// 			// let newSize = Math.floor(containerWidth / 250);
	// 			let newSize;
	// 			if (containerWidth === 530) {
	// 				newSize = 2;
	// 			} else {
	// 				newSize = 1;
	// 			}
	// 			// newSize < 1 && (newSize = 1);
	// 			setChunkSize(newSize);
	// 		} else {
	// 			setChunkSize(maxNumOfColumns);
	// 		}
	// 	}
	// };
	const screenSize = window.innerWidth;
	useEffect(() => {
		const xheadings = document.querySelectorAll(".evidence-map-x-heading");
		const stickyElements = document.querySelectorAll(".sticky");
		const table = document.querySelectorAll("table");
		if (toggleHorizontalScrolling) {
			if (table) {
				xheadings.forEach((xheading) => {
					xheading.classList.remove("phoneSticky");
				});
				stickyElements.forEach((stickyElement) => {
					stickyElement.style.top = "68px";
				});
			}
		} else {
			if (table) {
				xheadings.forEach((xheading) => {
					xheading.classList.add("phoneSticky");
				});
				stickyElements.forEach((stickyElement) => {
					stickyElement.style.top = "68px";
				});
			}
		}
	}, [chunkedXAxisLabels, screenSize, toggleHorizontalScrolling]);
	//For responsive - sticky and stuff
	useEffect(() => {
		const table = document.querySelectorAll("table");
		const tcontainer = document.getElementsByClassName("table-container");
		const tableContainer = tcontainer[0];
		const xheadings = document.querySelectorAll(".evidence-map-x-heading");
		const stickyElements = document.querySelectorAll(".sticky");
		if (screenSize >= 768) {
			if (table.length === 0 || table.length === 1) {
				if (table[0]) {
					xheadings.forEach((xheading) => {
						xheading.classList.remove("phoneSticky");
					});
					stickyElements.forEach((stickyElement) => {
						stickyElement.style.top = "68px";
					});
				}
			}
		} else {
			tableContainer.style.height = "100%";
			xheadings.forEach((xheading) => {
				if (!toggleHorizontalScrolling) {
					xheading.classList.add("phoneSticky");
				}
			});
			stickyElements.forEach((stickyElement) => {
				if (xheadings[0].getAttribute("colspan") >= 3) {
					stickyElement.style.top = "68px";
				} else {
					// if (
					// 	xheadings[0].textContent === "Intervention (Modality)"
					// ) {
					stickyElement.style.top = "68px";
					// }
				}
			});
		}
		// eslint-disable-next-line
	}, [chunkedXAxisLabels, screenSize]);

	useEffect(() => {
		calculateChunkSize();
		window.addEventListener("resize", calculateChunkSize);
		return () => {
			window.removeEventListener("resize", calculateChunkSize);
		};
		// eslint-disable-next-line
	}, [chunkedXAxisLabels, screenSize, toggleHorizontalScrolling]);

	useEffect(() => {
		const handleResize = () => {
			if (
				document.getElementsByClassName("table-container").length > 0 &&
				document.getElementsByClassName("evidence-map-y-heading")
					.length > 0
			) {
				setXheadingWidth(
					document.getElementsByClassName("table-container")[0]
						.clientWidth -
						document.getElementsByClassName(
							"evidence-map-y-heading"
						)[0].clientWidth
				);
			}
		};

		const timer = setTimeout(() => {
			handleResize();
			window.addEventListener("resize", handleResize);

			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}, 10);

		return () => {
			clearTimeout(timer); // Clear the timeout if the component unmounts before 50 seconds
		};
	}, []);
	// useEffect(() => {
	// 	const handleResize = () => {
	// 		if (
	// 			document.getElementsByClassName("table-container").length > 0 &&
	// 			document.getElementsByClassName("evidence-map-y-heading")
	// 				.length > 0 &&
	// 			window.innerWidth >= 768
	// 		) {
	// 			setXheadingWidth(
	// 				document.getElementsByClassName("table-container")[0]
	// 					.clientWidth -
	// 					document.getElementsByClassName(
	// 						"evidence-map-y-heading"
	// 					)[0].clientWidth
	// 			);
	// 		}
	// 	};

	// 	const timer = setTimeout(() => {
	// 		handleResize();
	// 		window.addEventListener("resize", handleResize);

	// 		return () => {
	// 			window.removeEventListener("resize", handleResize);
	// 		};
	// 	}, 10);

	// 	return () => {
	// 		clearTimeout(timer); // Clear the timeout if the component unmounts before 50 seconds
	// 	};
	// }, []);

	useEffect(() => {
		//THIS SORTS DESCENDING BASED ON THE AMOUNT OF BUBBLES NOT UNIQUE ARTICTLES THATS WHY
		//THE TOTAL UNIQUE REPORTS ON THE YAXIS LABEL ISN'T SORTED.
		//TOTAL BUBBLES PER COLUMN
		// 	"Reiki"= 129
		// 	"Therapeutic Touch"= 97
		// 	"Healing Touch"= 47
		// 	"Undefined/Bespoke"= 47
		// 	"Prayer"= 35
		// 	"Intercessory Prayer"= 30
		// 	"Spiritual Healing/Spiritist Passe"= 19
		// 	"External Qigong"= 13
		// 	"Remote/Distance Healing"=12
		// 	"Yakson Touch"= 9
		// 	"Okada Purifying Therapy"= 8
		// 	"Chunsoo Energy Healing"= 7
		// 	"Laying On Of Hands"= 6
		// 	"Johrei"= 5
		// 	"Pranic Healing"=5
		// 	"Brennan Healing Science"= 4
		// 	"Catalonia Technique"= 2
		// 	"Direct Mental Interaction With Living Systems"= 2
		// 	"Native American Method (NAM)"= 2
		// 	"Oscillatory Biofield Therapy"= 2
		// 	"Polarity Therapy"= 2
		// 	"Reconnective Healing" = 1

		const sortedXAxisLabels = Object.keys(cleanData).sort((key1, key2) => {
			return calculateColumnSum(key2) - calculateColumnSum(key1)
		});
		
		const chunkedXAxisLabelsTmp = [];
		for (let i = 0; i < sortedXAxisLabels.length; i += chunkSize) {
			chunkedXAxisLabelsTmp.push(
				sortedXAxisLabels.slice(i, i + chunkSize)
			);
		}
		setChunkedXAxisLabels(chunkedXAxisLabelsTmp);
		let noReturnedData = false;
		const tablesHaveData = {};
		const tableColsHaveData = {};
		chunkedXAxisLabelsTmp.forEach((chunk, index) => {
			chunk.forEach((xAxisLabel, index_x) => {
				if (!tableColsHaveData[index]) {
					tableColsHaveData[index] = {};
				}
				yAxisLabels.some((yAxisLabel, index_y) => {
					tableColsHaveData[index][index_x] =
						cleanData[xAxisLabel][yAxisLabel]["ids"].length > 0;
					return cleanData[xAxisLabel][yAxisLabel]["ids"].length > 0;
				});
			});
			tablesHaveData[index] = Object.values(
				tableColsHaveData[index]
			).some((value) => value);
		});

		noReturnedData = Object.values(tableColsHaveData).every((obj) =>
			Object.values(obj).every((value) => !value)
		);
		setMapVisibility({ noReturnedData, tablesHaveData, tableColsHaveData });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cleanData, chunkSize]);

	return (
		<>
			<div className="table-container" id="table-container">
				{mapVisibility && !mapVisibility.noReturnedData ? (
					chunkedXAxisLabels.map((chunk, index) => {
						return (
							mapVisibility.tablesHaveData[index] && (
								<table
									key={`table-${index}`}
									className="table table-borderless rrt"
								>
									<thead
										style={{
											backgroundColor: "white",
											border: "1px solid white",
										}}
									>
										<tr>
											<td className="evidence-map-top-left-white " />
											<th
												className="evidence-map-x-heading"
												style={
													toggleHorizontalScrolling
														? {
																width: xHeadingWidth,
														  }
														: {}
												}
												// style={
												// 	window.innerWidth >= 768
												// 		? {
												// 				width: xHeadingWidth,
												// 		  }
												// 		: {}
												// }
												colSpan={chunk.length + 1}
											>
												{xAxisTitle ===
												"intervention_bucket"
													? "Intervention (Biofield Modality)"
													: xAxisTitle ===
													  "condition_category"
													? "Health Condition"
													: xAxisTitle ===
													  "direction_of_results"
													? "Direction of Study Results"
													: xAxisTitle
															.split("_")
															.map(
																(word) =>
																	word
																		.charAt(
																			0
																		)
																		.toUpperCase() +
																	word.slice(
																		1
																	)
															)
															.join(" ")}
											</th>
										</tr>
										<tr className="sticky">
											<th className="evidence-map-y-heading">
												{yAxisTitle ===
												"intervention_bucket"
													? "Intervention (Biofield Modality)"
													: yAxisTitle ===
													  "condition_category"
													? "Health Condition"
													: yAxisTitle ===
													  "direction_of_results"
													? "Direction of Study Results"
													: yAxisTitle
															.split("_")
															.map(
																(word) =>
																	word
																		.charAt(
																			0
																		)
																		.toUpperCase() +
																	word.slice(
																		1
																	)
															)
															.join(" ")}
											</th>

											{chunk.map((xAxisLabel) => (
												<th
													key={xAxisLabel}
													className="evidence-map-x-heading-categories borders"
												>
													{xAxisLabel}
												</th>
											))}
											<th className="evidence-map-x-heading-categories borders">
												Total Unique Reports<a href="#footnote">*</a>
											</th>
										</tr>
									</thead>
									<tbody>
										{yAxisLabels.map(
											(yAxisLabel, index_y) => {
												return (
													<tr
														key={
															"row-" + yAxisLabel
														}
														style={{
															border: "1px solid #d3d3d3",
															borderLeft:
																"2px solid white", // Remove the left border
														}}
													>
														<td className="evidence-map-y-heading-categories borders">
															{yAxisLabel}
														</td>

														{chunk.map(
															(
																xAxisLabel,
																newIndex
															) => {
																const totalReports =
																	cleanData[
																		xAxisLabel
																	][
																		yAxisLabel
																	]["ids"]
																		.length;

																const reportsDirection =
																	{
																		Positive:
																			[],
																		Mixed: [],
																		Negative:
																			[],
																		Others: [],
																		NR: [],
																	};

																Object.keys(
																	reportsDirection
																).forEach(
																	(
																		directionType
																	) => {
																		Object.keys(
																			papersResult
																		).forEach(
																			(
																				paperTitle
																			) => {
																				if (
																					papersResult[
																						paperTitle
																					] ===
																						";Positive;" &&
																					cleanData[
																						xAxisLabel
																					][
																						yAxisLabel
																					][
																						"ids"
																					].includes(
																						parseInt(
																							paperTitle
																						)
																					)
																				) {
																					reportsDirection.Positive.push(
																						paperTitle
																					);
																				} else if (
																					(papersResult[
																						paperTitle
																					] ===
																						";Not Significant;" ||
																						papersResult[
																							paperTitle
																						] ===
																							";NS;") &&
																					cleanData[
																						xAxisLabel
																					][
																						yAxisLabel
																					][
																						"ids"
																					].includes(
																						parseInt(
																							paperTitle
																						)
																					)
																				) {
																					reportsDirection.Others.push(
																						paperTitle
																					);
																				} else if (
																					papersResult[
																						paperTitle
																					] ===
																						";Negative;" &&
																					cleanData[
																						xAxisLabel
																					][
																						yAxisLabel
																					][
																						"ids"
																					].includes(
																						parseInt(
																							paperTitle
																						)
																					)
																				) {
																					reportsDirection.Negative.push(
																						paperTitle
																					);
																				} else if (
																					papersResult[
																						paperTitle
																					] ===
																						";Mixed;" &&
																					cleanData[
																						xAxisLabel
																					][
																						yAxisLabel
																					][
																						"ids"
																					].includes(
																						parseInt(
																							paperTitle
																						)
																					)
																				) {
																					reportsDirection.Mixed.push(
																						paperTitle
																					);
																				} else if (
																					papersResult[
																						paperTitle
																					] ===
																						";Not Reported;" &&
																					cleanData[
																						xAxisLabel
																					][
																						yAxisLabel
																					][
																						"ids"
																					].includes(
																						parseInt(
																							paperTitle
																						)
																					)
																				) {
																					reportsDirection.NR.push(
																						paperTitle
																					);
																				}
																			}
																		);
																	}
																);

																// Remove duplicates from the "Others" array
																reportsDirection.Others =
																	[
																		...new Set(
																			reportsDirection.Others
																		),
																	];
																reportsDirection.NR =
																	[
																		...new Set(
																			reportsDirection.NR
																		),
																	];
																reportsDirection.Positive =
																	[
																		...new Set(
																			reportsDirection.Positive
																		),
																	];
																reportsDirection.Mixed =
																	[
																		...new Set(
																			reportsDirection.Mixed
																		),
																	];
																reportsDirection.Negative =
																	[
																		...new Set(
																			reportsDirection.Negative
																		),
																	];

																if (
																	totalReports
																) {
																	return (
																		<td
																			key={
																				xAxisLabel
																			}
																			className="align-middle borders"
																		>
																			<div
																				style={{
																					display:
																						"flex",
																				}}
																			>
																				{Object.keys(
																					reportsDirection
																				).map(
																					(
																						item
																					) => {
																						if (
																							!(
																								reportsDirection[
																									item
																								]
																									.length ===
																								0
																							)
																						) {
																							return (
																								<div
																									onClick={() => {
																										handleShowReports(
																											xAxisLabel,
																											yAxisLabel,
																											item
																										);
																									}}
																									key={
																										item
																									}
																									className={`em-cell-circle ${item}`}
																									style={{
																										width: getBubblePerimeter(
																											reportsDirection[
																												item
																											]
																												.length,
																											maxNumOfReports
																										),
																										height: getBubblePerimeter(
																											reportsDirection[
																												item
																											]
																												.length,
																											maxNumOfReports
																										),
																										fontSize:
																											getBubblePerimeter(
																												reportsDirection[
																													item
																												]
																													.length,
																												maxNumOfReports
																											) /
																											2,
																									}}
																								>
																									{
																										reportsDirection[
																											item
																										]
																											.length
																									}
																								</div>
																							);
																						}
																						return null;
																					}
																				)}
																			</div>
																		</td>
																	);
																} else {
																	return (
																		<td
																			className="align-middle borders"
																			key={
																				"cell-" +
																				yAxisLabel +
																				"-" +
																				xAxisLabel
																			}
																		/>
																	);
																}
															}
														)}
														<td className="align-middle borders total-reports-cell">
															{calculateRowSum(
																chunk,
																yAxisLabel
															)}
														</td>
													</tr>
												);
											}
										)}
										<tr>
											<td className="evidence-map-y-heading-categories borders">
												Total Unique Reports<a href="#footnote">*</a>
											</td>
											{chunk.map((xAxisLabel) => {
												const columnSum =
													calculateColumnSum(
														xAxisLabel
													);
												return (
													<td
														key={
															"column-sum-" +
															xAxisLabel
														}
														className="align-middle borders total-reports-cell"
													>
														{columnSum}
													</td>
												);
											})}
											<td className="align-middle borders total-reports-cell" />
										</tr>
									</tbody>
								</table>
							)
						);
					})
				) : (
					<div className="no-results-container">
						<Typography variant="body2">
							The search term and the selected filters returned no
							reports.
						</Typography>
					</div>
				)}
			</div>
		</>
	);
}

export default ReportsTable;
