import React from "react";
import "../css/Header.css";
// import methodologyIcon from "../images/final evidence map logo and about-02.svg";
// import aboutIcon from "../images/client_logo_mini.svg";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const Header = () => {
	return (
		<>
			<div className="container header-container">
				<div
					className="header-left-section"
					style={{
						textAlign: "left",
						margin: "0",
					}}
				>
					<h1
						style={{
							marginTop: "1rem",
							fontSize: "32px",
						}}
					>
						<a href="/" className="em-title">
							Biofield Science{" "}
							<span id="break-header-title">
								<br />
							</span>
							Evidence Map
						</a>
					</h1>
				</div>
				<div className="break"></div>
				<div className="header-right-section">
					{/* <a href="/methods">Methodology</a>
					<a href="/about">About</a> */}
					<a
						className="subpage-button-container"
						id="about-button-container"
						href="/about"
						style={{ cursor: "pointer" }}
					>
						{/* <div className="notes-icon">
							<img
								src={aboutIcon}
								style={{ height: 30 }}
								alt="Report"
							/>
						</div> */}
						<div className="subpage-button-text text">About</div>
					</a>
					<a
						className="subpage-button-container"
						id="methods-button-container"
						href="/methods"
					>
						{/* <div className="notes-icon">
							<img src={methodologyIcon} alt="Report" />
						</div> */}
						<div className="subpage-button-text text">
							Map Methodology
						</div>
					</a>
					<a
						className="subpage-button-container"
						id="methods-button-container"
						href="/about#how_to_use"
						style={{
							alignItems: "center",
						}}
					>
						{/* <div className="notes-icon">
							<HelpOutlineIcon />
						</div> */}
						<div className="subpage-button-text text">
							How To Use
						</div>
					</a>
				</div>
			</div>
		</>
	);
};

export default Header;
