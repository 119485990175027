import React from "react";
import { formatAxisTitle } from "../functions/helperFunctions";
import {
	Typography,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	IconButton,
} from "@mui/material/";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

function TableTopContainer({
	setSelectedX,
	setSelectedY,
	selectedAxis,
	setSelectedAxis,
	axisCombination,
	toggleHorizontalScrolling,
	setToggleHorizontalScrolling,
}) {
	const CustomTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			color: "#212529",
			boxShadow: "0 3px 14px rgba(0,0,0,0.4)",
			padding: "14px 25px 14px 21px",
			fontSize: "12px",
			transform: "translate3d(531px, 209px, 0px);",
			transition: "opacity 0.2s linear",
			marginBottom: 20,
			transformOrigin: "0 0",
		},
	}));

	return (
		<div className="table-top-container">
			<FormControl
				id="view-selection-container"
				key="view-selection-container"
				size="small"
				className="filter"
			>
				<InputLabel>Change Table View</InputLabel>
				<Select
					labelId="view-filter-label"
					id="view-filter"
					value={selectedAxis}
					label="Change Table View"
					onChange={(e) => {
						const parts = e.target.value.split(" - ");
						setSelectedX(parts[0]);
						setSelectedY(parts[1]);
						setSelectedAxis(e.target.value);
						sessionStorage.setItem("selectedX", parts[0]);
						sessionStorage.setItem("selectedY", parts[1]);
						sessionStorage.setItem("selectedAxis", e.target.value);
					}}
				>
					{axisCombination.map((title) => (
						<MenuItem
							key={title}
							value={title}
							disabled={title === selectedAxis}
						>
							{formatAxisTitle(title)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl
				id="view-horizontal-scrolling"
				key="view-horizontal-scrolling"
				size="small"
				className="filter"
			>
				<InputLabel>Enable Horizontal Scrolling</InputLabel>
				<Select
					labelId="horizontal-scrolling"
					id="horizontal-scrolling"
					value={toggleHorizontalScrolling}
					label="Enable Horizontal Scrolling"
					onChange={(e) => {
						setToggleHorizontalScrolling(e.target.value);
					}}
				>
					<MenuItem
						key="true"
						value={true}
						// disabled={toggleHorizontalScrolling === true}
					>
						Yes
					</MenuItem>
					<MenuItem
						key="false"
						value={false}
						// disabled={toggleHorizontalScrolling === false}
					>
						No
					</MenuItem>
				</Select>
			</FormControl>
			<div className="bubble-chart-legend-container">
				<CustomTooltip
					title={
						<div>
							<p>
								An assessment of study results was made for each
								article based on the authors' own stated
								conclusions (usually found in the abstract).
								Results have not been independently assessed by
								the researchers who performed the scoping review
								underlying this evidence map.{" "}
							</p>
							<p>
								<span
									className="legend-colorBox"
									style={{
										display: "inline-block",
										backgroundColor: "lightgreen",
									}}
								></span>
								<span>
									<i>Positive Results</i>: Outcomes assessed
									in the study were reported as statistically
									significant by the author.
								</span>
							</p>
							<p>
								<span
									className="legend-colorBox"
									style={{
										display: "inline-block",
										backgroundColor: "rgb(147, 176, 199)",
									}}
								></span>
								<span>
									<i>Mixed Results</i>: Results of outcomes
									assessed in the study varied, as reported by
									the author.
								</span>
							</p>
							<p>
								<span
									className="legend-colorBox"
									style={{
										display: "inline-block",
										backgroundColor: "lightgray",
									}}
								></span>
								<span>
									<i>Not Significant Results</i>: Outcomes
									assessed in the study showed no
									statistically significant improvement (were
									null), as reported by the author.
								</span>
							</p>
							<p>
								<span
									className="legend-colorBox"
									style={{
										display: "inline-block",
										backgroundColor: "lightcoral",
									}}
								></span>
								<span>
									<i>Negative Results</i>: Outcomes assessed
									in the study showed no statistically
									significant improvement or declined over
									time, as reported by the author.
								</span>
							</p>
							<p>
								<span
									className="legend-colorBox"
									style={{
										display: "inline-block",
										backgroundColor: "rgb(255, 245, 201)",
									}}
								></span>
								<span>
									<i>Not Reported</i>: The author did not
									disclose the direction of the outcomes
									within the article.
								</span>
							</p>
						</div>
					}
					placement="bottom-end"
					enterTouchDelay={0}
				>
					<Typography
						variant="caption"
						style={{
							color: "rgba(0, 0, 0, 0.6)",
							cursor: "pointer",
						}}
					>
						Direction of Study Results
						<HelpOutlineRoundedIcon
							style={{
								fontSize: 16,
								marginLeft: 4,
								marginBottom: 4,
							}}
						/>
					</Typography>
				</CustomTooltip>
				<div className="bubble-chart-legend">
					<div className="legend-item">
						<span
							className="legend-colorBox"
							style={{
								backgroundColor: "lightgreen",
							}}
						></span>
						<span className="legend-label">Positive</span>
					</div>
					<div className="legend-item">
						<span
							className="legend-colorBox"
							style={{
								backgroundColor: "#93b0c7",
							}}
						></span>
						<span className="legend-label">Mixed</span>
					</div>
					<div className="legend-item">
						<span
							className="legend-colorBox"
							style={{ backgroundColor: "lightgray" }}
						></span>
						<span className="legend-label">Not Significant</span>
					</div>
					<div className="legend-item">
						<span
							className="legend-colorBox"
							style={{
								backgroundColor: "lightcoral",
							}}
						></span>
						<span className="legend-label">Negative</span>
					</div>
					<div className="legend-item">
						<span
							className="legend-colorBox"
							style={{ backgroundColor: "#fff5c9" }}
						></span>
						<span className="legend-label">Not Reported</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TableTopContainer;
