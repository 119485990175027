import React, { useState } from "react";
import {
	IconButton,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const EmailInputModal = ({ isOpen, onProceed }) => {
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [userType, setUserType] = useState(null);
	const [emailError, setEmailError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [surnameError, setSurnameError] = useState(false);
	const [userTypeError, setUserTypeError] = useState(false);
	if (!isOpen) {
		return null;
	}

	const handleProceed = () => {
		setEmailError(false);
		setNameError(false);
		setSurnameError(false);
		if (name === "") {
			setNameError(true);
		} else if (surname === "") {
			setSurnameError(true);
		} else if (userType === null) {
			setUserTypeError(true);
		} else if (isValidEmail(email)) {
			onProceed(email, name, surname, userType);
		} else {
			setEmailError(true);
		}
	};
	const isValidEmail = (value) => {
		// Simple email validation, you might want to use a more robust solution
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(value);
	};
	const handleEmailChange = (e) => {
		const inputValue = e.target.value;
		setEmail(inputValue);
		setEmailError(false);
	};
	const handleNameChange = (e) => {
		const inputValue = e.target.value;
		setName(inputValue);
	};
	const handleSurnameChange = (e) => {
		const inputValue = e.target.value;
		setSurname(inputValue);
	};

	const handleDropdownChange = (e) => {
		setUserType(e.target.value);
	};
	return (
		<>
			{isOpen ? (
				<>
					<div
						className="modal show email-input-modal"
						id="mojListModal"
						tabIndex="-1"
						aria-labelledby="mojListModal"
						aria-modal="true"
						role="dialog"
					>
						<div
							className="modal-dialog modal-dialog-scrollable modal-lg"
							style={{ maxWidth: "500px" }}
						>
							<div className="modal-content">
								<div className="modal-header">
									<h5
										className="modal-title"
										id="dwpListModalTitle"
										style={{ fontSize: "18px" }}
									>
										<b>
											Please provide the following
											information to access a summary of
											the article and link to the full
											publication:
										</b>
									</h5>
								</div>
								<div
									className="modal-body"
									style={{
										display: "flex",
										flexDirection: "column", // Change to "column" for stacked, "row" for side by side
										gap: "10px",
									}}
								>
									<div
										style={{ display: "flex", gap: "10px" }}
									>
										<TextField
											fullWidth
											id="name-input"
											label="First Name"
											size="small"
											value={name}
											error={nameError}
											onChange={handleNameChange}
										/>
										<TextField
											fullWidth
											id="surname-input"
											label="Last Name"
											size="small"
											value={surname}
											error={surnameError}
											onChange={handleSurnameChange}
										/>
									</div>
									<TextField
										fullWidth
										id="email-input"
										label="Email"
										size="small"
										type="email"
										error={emailError}
										helperText={
											emailError
												? "Invalid email address"
												: ""
										}
										value={email}
										onChange={handleEmailChange}
									/>
									<FormControl
										size="small"
										className="filter"
										error={userTypeError}
									>
										<InputLabel id="demo-simple-select-label">
											I am a
										</InputLabel>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={userType}
											label="I am a"
											onChange={handleDropdownChange}
										>
											<MenuItem value={"Researcher"}>
												Researcher
											</MenuItem>
											<MenuItem
												value={
													"Biofield healing practitioner"
												}
											>
												Biofield healing practitioner
											</MenuItem>
											<MenuItem
												value={
													"Healthcare professional"
												}
											>
												Healthcare professional
											</MenuItem>
											<MenuItem value={"Policy maker"}>
												Policy maker
											</MenuItem>
											<MenuItem value={"Funder"}>
												Funder
											</MenuItem>
											<MenuItem value={"Patient"}>
												Patient
											</MenuItem>
											<MenuItem value={"Other"}>
												Other
											</MenuItem>
										</Select>
									</FormControl>
									<span
										style={{
											fontSize: 14,
											color: "#808080",
										}}
									>
										Your contact details will be securely
										saved and may be used for occasional
										communications related to the Biofield
										Science Evidence Map or important
										activity for the biofield science
										community. You'll be able to opt out as
										desired.
									</span>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "10px",
										}}
									>
										<div
											onClick={() =>
												window.history.back()
											}
											id="email-modal-button-back"
											style={{ cursor: "pointer" }}
										>
											<IconButton
												size="small"
												disableRipple={true}
												disableFocusRipple={true}
											>
												<ArrowBackIosNewIcon />
											</IconButton>
											Back
										</div>

										<button
											onClick={handleProceed}
											id="email-modal-button-submit"
										>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop show"></div>
				</>
			) : null}
		</>
	);
};

export default EmailInputModal;
