import React from "react";
import "../css/LoadingSpinner.css";

function LoadingSpinner() {
	return (
		<>
			<div className="spinner-container">
				<div className="lds-grid">
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		</>
	);
}

export default LoadingSpinner;
