import React from "react";
import clientsPageLandingLogo from "../images/clients_page_landing_logo.svg";
import methodologyIcon from "../images/final evidence map logo and about-02.svg";
import aboutIcon from "../images/client_logo_mini.svg";
import ClientLogo from "../images/client_logo.svg";

function Bottom() {
	return (
		<div id="bottom">
			<div
				className="row"
				style={{ marginLeft: 0, marginTop: 30, height: 50 }}
			>
				<div id="logo" style={{ marginRight: 10 }}>
					<a
						href="https://evidencemap.com/"
						id="copyright-link"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							id="em"
							src={clientsPageLandingLogo}
							alt="Evidence Map logo"
							className="img-fluid"
							style={{ width: 150, marginBottom: "2rem" }}
						/>
					</a>
				</div>
				<div
					id="line-wide"
					style={{
						marginTop: "0.7rem",
						width: "calc(100% - 330px)",
					}}
				>
					<hr
						style={{
							height: 1,
							backgroundColor: "#d3d3d3",
							borderTop: "none",
							marginTop: "1.3rem",
						}}
					/>
				</div>
				<div id="line-text">
					<a
						href="https://evidencemap.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Made with evidencemap.com
					</a>
				</div>

				<div
					id="line"
					style={{
						marginTop: "0.7rem",
						width: "calc(100% - 180px)",
					}}
				>
					<hr
						style={{
							height: 1,
							backgroundColor: "#d3d3d3",
							borderTop: "none",
							marginTop: "1.3rem",
						}}
					/>
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<p
					className="upload-excel-container"
					style={{ marginRight: "auto" }}
				>
					Do you have feedback to help us improve this Evidence Map?
					Contact us at:{" "}
					<a
						className="custom-link"
						href="mailto:admin@funderscollective.com?subject=Feedback%20on%20Biofield%20Science%20Evidence%20Map&body=Dear%20Team,%0D%0A%0D%0AI%20have%20some%20feedback%20to%20share%20regarding%20the%20Biofield%20Science%20Evidence%20Map.%20%0D%0A%0D%0AHere%20are%20my%20thoughts:%0D%0A%0D%0A%0D%0A-%20%0D%0A%0D%0AThank%20you%20for%20the%20opportunity%20to%20provide%20input.%0D%0A%0D%0ASincerely,%0D%0A[Your%20Name]"
					>
						admin@funderscollective.com
					</a>
				</p>
				<p
					className="upload-excel-container logo-description"
					id="logo-description-desktop"
				>
					The Biofield Science Evidence Map was made possible
					<br />
					through funding support from members of:
				</p>
			</div>
			<div className="subpages-container">
				<div className="bottom-client-logo-container">
					<p
						className="upload-excel-container logo-description"
						id="logo-description-mobile"
					>
						The Biofield Science Evidence Map was made possible
						<br />
						through funding support from members of:
					</p>
					<a
						href="/"
						style={{
							display: "flex",
							width: "200px",
							height: "60px",
						}}
					>
						<img
							id="client_logo"
							src={ClientLogo}
							alt="ClientLogo"
							className="img-fluid"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Bottom;
