import React from "react";
import { Link } from "react-router-dom";

const ReportsModal = ({ isOpen, onClose, reports }) => {
	if (!isOpen) {
		return null;
	}
	const closeModal = (e) => {
		if (e.target.classList.value === "modal show") {
			onClose();
		}
	};
	return (
		<>
			<div
				className="modal show"
				id="mojListModal"
				tabIndex="-1"
				aria-labelledby="mojListModal"
				aria-modal="true"
				role="dialog"
				style={{ display: "block" }}
				onClick={closeModal}
			>
				<div className="modal-dialog modal-dialog-scrollable modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5
								className="modal-title"
								id="dwpListModalTitle"
								style={{ fontSize: "18px" }}
							>
								Reports in this category ({reports.length})
							</h5>

							<button
								type="button"
								className="close"
								data-dismiss="modal"
								onClick={onClose}
								aria-label="Close"
								id="closeModal"
							>
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							<ul
								style={{
									listStyleType: "none",
									paddingLeft: "0px",
								}}
							>
								{reports.length > 0 ? (
									reports.map((report, index) => (
										<div
											key={
												"key" + report.id + "_" + index
											}
											className="modal-link report-links"
										>
											<Link
												to={{
													pathname: `/report/${report.covidence}`,
												}}
												state={{
													otherReports: reports,
												}}
												className="modal-link report-links"
											>
												<li>
													{report.title +
														" - " +
														report.authors +
														" (" +
														report.year +
														")" +
														" " +
														report.journal}
												</li>
												<hr />
											</Link>
										</div>
									))
								) : (
									<div>No reports found</div>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop show"></div>
		</>
	);
};

export default ReportsModal;
