import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField } from "@mui/material/";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

function SearchBar({ searchTerm, setSearchTerm }) {
	const [placeholderText, setPlaceholderText] = useState(null);
	const [typedText, setTypedText] = useState(searchTerm);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 1210) {
				setPlaceholderText(
					"Start typing keywords separated by comma (,)"
				);
			} else {
				setPlaceholderText(
					"Start typing keywords separated by comma (,) to search for relevant reports"
				);
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const submitSearchTerm = (e) => {
		e.preventDefault();
		setSearchTerm(typedText);
		if (typedText !== "") {
			sessionStorage.setItem("searchTerm", typedText);
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			submitSearchTerm(e);
		}
	};
	useEffect(() => {
		setTypedText(searchTerm);
	}, [searchTerm]);
	return (
		<>
			{placeholderText && (
				<TextField
					id="search"
					type="search"
					label="Search Evidence Map"
					placeholder={placeholderText}
					onChange={(e) => setTypedText(e.target.value)}
					onBlur={submitSearchTerm}
					onKeyDown={handleKeyDown} // Add this event listener
					className="search-bar"
					size="small"
					value={typedText || ""}
				/>
			)}
		</>
	);
}

export default SearchBar;
