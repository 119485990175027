//For table axis titles
export function formatAxisTitle(title) {
	return title
		.split(" - ")
		.map((phrase) => {
			return phrase === "intervention_bucket"
				? "Intervention (Biofield Modality)"
				: phrase === "condition_category"
				? "Health Condition"
				: phrase === "direction_of_results"
				? "Direction of Study Results"
				: phrase
						.split("_")
						.map(
							(word) =>
								word.charAt(0).toUpperCase() + word.slice(1)
						)
						.join(" ");
		})
		.join(" - ");
}

//For table bubbles perimeter
export function getBubblePerimeter(totalReports, maxReports) {
	// Define the minimum and maximum circle sizes
	const minSize = 20;
	const maxSize = 100;

	// Ensure that the provided totalReports and maxReports are numbers
	if (
		typeof totalReports !== "number" ||
		typeof maxReports !== "number" ||
		maxReports <= 0
	) {
		throw new Error("Invalid input values. Please provide valid numbers.");
	}

	// Use linear scaling to map totalReports to the circle size range
	// const size = (totalReports / maxReports) * (maxSize - minSize) + minSize;
	const size = maxSize * Math.sqrt(totalReports / maxReports);

	// Ensure the size is within the valid range
	return Math.max(minSize, size);
}

// Custom sorting function
export function customSort(a, b) {
	if (typeof a === "number" && typeof b === "number") {
		return a - b; // Sort as numbers
	} else {
		return a.toString().localeCompare(b.toString()); // Sort as strings
	}
}
