import React, { useEffect, useState } from "react";
import "../css/Home.css";
import axios from "axios";
import ReportsModal from "../components/ReportsModal.jsx";
import Filters from "../components/Filters.jsx";
import ReportsTable from "../components/ReportsTable";
import Bottom from "../components/Bottom";
import SearchBar from "../components/SearchBar";
import IntroTextHome from "../components/IntroTextHome.jsx";
import Header from "../components/Header.jsx";
import LoadingSpinner from "../components/LoadingSpinner.jsx";
import TableTopContainer from "../components/TableTopContainer";
import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material/";
import "../css/Footer.css";
import { useNavigate } from "react-router-dom";

import { customSort } from "../functions/helperFunctions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
function Home() {
	const [dataRaw, setDataRaw] = useState();
	const [axisLabels, setAxisLabels] = useState();
	const [axisToSend, setAxisToSend] = useState();
	const [cleanData, setCleanData] = useState();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [currentReports, setCurrentReports] = useState([]);
	const [toggleHorizontalScrolling, setToggleHorizontalScrolling] =
		useState(true);
	const toggleAdvancedFilters = () => {
		setShowAdvancedFilters(!showAdvancedFilters);
	};
	const [searchTerm, setSearchTerm] = useState(() => {
		if (Object.keys(sessionStorage).includes("searchTerm")) {
			return sessionStorage.getItem("searchTerm");
		} else {
			return "";
		}
	});
	//Loading spinners flags
	const [filtersLoading, setFiltersLoading] = useState(true);
	const [shouldRenderTable, setShouldRenderTable] = useState(false); //for the issue rendering table before setting new axis labels
	// Primary Filters Variables
	const [primaryFilters, setPrimaryFilters] = useState();
	const [primaryFiltersSelected, setPrimaryFiltersSelected] = useState(() => {
		if (Object.keys(sessionStorage).includes("primaryFiltersSelected")) {
			return JSON.parse(sessionStorage.getItem("primaryFiltersSelected"));
		} else {
			return;
		}
	});
	const [isCheckAllPrimaryFilters, setIsCheckAllPrimaryFilters] = useState(
		() => {
			if (
				Object.keys(sessionStorage).includes("isCheckAllPrimaryFilters")
			) {
				return JSON.parse(
					sessionStorage.getItem("isCheckAllPrimaryFilters")
				);
			} else {
				return;
			}
		}
	);

	// Advanced Filters Variables
	const [advancedFilters, setAdvancedFilters] = useState();
	const [advancedFiltersSelected, setAdvancedFiltersSelected] = useState(
		() => {
			if (
				Object.keys(sessionStorage).includes("advancedFiltersSelected")
			) {
				return JSON.parse(
					sessionStorage.getItem("advancedFiltersSelected")
				);
			} else {
				return;
			}
		}
	);
	const [isCheckAllAdvancedFilters, setIsCheckAllAdvancedFilters] = useState(
		() => {
			if (
				Object.keys(sessionStorage).includes(
					"isCheckAllAdvancedFilters"
				)
			) {
				return JSON.parse(
					sessionStorage.getItem("isCheckAllAdvancedFilters")
				);
			} else {
				return;
			}
		}
	);

	const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
	//For chaning AXIS tables
	const axisCombination = [
		"intervention_bucket - condition_category",
		"condition_category - intervention_bucket",
		"intervention_bucket - outcome_category",
		"direction_of_results - intervention_bucket",
		"direction_of_results - condition_category",
	];
	const [selectedAxis, setSelectedAxis] = useState(() => {
		if (Object.keys(sessionStorage).includes("selectedAxis")) {
			return sessionStorage.getItem("selectedAxis");
		}
		return axisCombination[0];
	});

	const [selectedX, setSelectedX] = useState(() => {
		if (Object.keys(sessionStorage).includes("selectedX")) {
			return sessionStorage.getItem("selectedX");
		}
		return "intervention_bucket";
	});
	const [selectedY, setSelectedY] = useState(() => {
		if (Object.keys(sessionStorage).includes("selectedY")) {
			return sessionStorage.getItem("selectedY");
		}
		return "condition_category";
	});
	const [paperResult, setPaperResult] = useState({});
	const navigate = useNavigate();
	useEffect(() => {
		const hasAccess = localStorage.getItem("has_access");
		if (
			!hasAccess ||
			hasAccess === null ||
			hasAccess === undefined ||
			!hasAccess
		) {
			navigate("/visit");
		}
	});

	useEffect(() => {
		setShouldRenderTable(false);
		axios({
			url: "/get_initial_data/",
		})
			.then((response) => {
				if (
					!dataRaw &&
					!Object.keys(sessionStorage).includes(
						"primaryFiltersSelected"
					) &&
					!Object.keys(sessionStorage).includes(
						"advancedFiltersSelected"
					)
				) {
					setDataRaw(response.data);
				}
				const placeholderPaperResult = { ...paperResult };
				// Loop through the response data and add each title and direction_of_results
				response.data.forEach((data) => {
					placeholderPaperResult[data.covidence] =
						data.direction_of_results;
				});
				// Update the state with the accumulated data
				setPaperResult(placeholderPaperResult);
				if (selectedX === "direction_of_results") {
					const uniqueXlabels = [
						...new Set(
							response.data.map((item) => item[selectedX])
						),
					];
					let finalX = [
						...new Set(
							uniqueXlabels.flatMap((item) =>
								item.split(";").map((value) => value.trim())
							)
						),
					];
					finalX = finalX.filter((item) => item !== "NS");
					finalX.sort(customSort);
					const customOrder = [
						"Positive",
						"Mixed",
						"Negative",
						"Not Significant",
						"Not Reported",
					];

					finalX.sort((a, b) => {
						return customOrder.indexOf(a) - customOrder.indexOf(b);
					});
					const uniqueYlabels = [
						...new Set(
							response.data.map((item) => item[selectedY])
						),
					];
					let finalY = [
						...new Set(
							uniqueYlabels.flatMap((item) =>
								item.split(";").map((value) => value.trim())
							)
						),
					];
					finalY.sort(customSort);

					finalX = finalX.filter((str) => str !== "");
					finalY = finalY.filter((str) => str !== "");
					// finalX = finalX.map((value) => {
					// 	if (value === "Negative") {
					// 		return "Not Significant or Negative";
					// 	} else {
					// 		return value;
					// 	}
					// });
					setAxisLabels({
						x: finalX,
						y: finalY,
					});
				} else {
					const uniqueXlabels = [
						...new Set(
							response.data.map((item) => item[selectedX])
						),
					];
					let finalX = [
						...new Set(
							uniqueXlabels.flatMap((item) =>
								item.split(";").map((value) => value.trim())
							)
						),
					];
					finalX.sort(customSort);
					uniqueXlabels.sort(customSort);
					const uniqueYlabels = [
						...new Set(
							response.data.map((item) => item[selectedY])
						),
					];
					let finalY = [
						...new Set(
							uniqueYlabels.flatMap((item) =>
								item.split(";").map((value) => value.trim())
							)
						),
					];
					finalY.sort(customSort);
					uniqueYlabels.sort(customSort);
					finalX = finalX.filter((str) => str !== "");
					finalY = finalY.filter((str) => str !== "");
					setAxisLabels({
						x: finalX,
						y: finalY,
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedY, selectedX]);

	useEffect(() => {
		const transformData = () => {
			const dataMap = {};
			axisLabels.x.forEach((x) => {
				dataMap[x] = {};
				axisLabels.y.forEach((y) => {
					dataMap[x][y] = { titles: [], ids: [] };
				});
			});
			if (selectedX === "direction_of_results") {
				Object.keys(dataMap).forEach((x) => {
					axisLabels.y.forEach((y) => {
						// Create a set to store unique titles
						const uniquePositive = new Set();
						const uniqueMixed = new Set();
						const uniqueNA = new Set();
						const uniqueNegative = new Set();
						const uniqueNR = new Set();
						const uniquePositiveIDS = new Set();
						const uniqueMixedIDS = new Set();
						const uniqueNAIDS = new Set();
						const uniqueNegativeIDS = new Set();
						const uniqueNRIDS = new Set();
						dataRaw.forEach((item) => {
							if (item[selectedY].includes(y)) {
								if (item[selectedX] === ";Negative;") {
									uniqueNegative.add(item.title);
									uniqueNegativeIDS.add(item.covidence);
								} else if (item[selectedX] === ";Positive;") {
									uniquePositive.add(item.title);
									uniquePositiveIDS.add(item.covidence);
								} else if (item[selectedX] === ";Mixed;") {
									uniqueMixed.add(item.title);
									uniqueMixedIDS.add(item.covidence);
								} else if (
									item[selectedX] === ";Not Significant;" ||
									item[selectedX] === ";NS;"
								) {
									uniqueNA.add(item.title);
									uniqueNAIDS.add(item.covidence);
								} else if (
									item[selectedX] === ";Not Reported;"
								) {
									uniqueNR.add(item.title);
									uniqueNRIDS.add(item.covidence);
								}
							}
						});
						if (x === "Negative") {
							dataMap["Negative"][y]["titles"] =
								Array.from(uniqueNegative);
							dataMap["Negative"][y]["ids"] =
								Array.from(uniqueNegativeIDS);
						} else if (x === "Positive") {
							dataMap["Positive"][y]["titles"] =
								Array.from(uniquePositive);
							dataMap["Positive"][y]["ids"] =
								Array.from(uniquePositiveIDS);
						} else if (x === "Mixed") {
							dataMap["Mixed"][y]["titles"] =
								Array.from(uniqueMixed);
							dataMap["Mixed"][y]["ids"] =
								Array.from(uniqueMixedIDS);
						} else if (x === "Not Significant") {
							dataMap["Not Significant"][y]["titles"] =
								Array.from(uniqueNA);
							dataMap["Not Significant"][y]["ids"] =
								Array.from(uniqueNAIDS);
						} else if (x === "Not Reported") {
							dataMap["Not Reported"][y]["titles"] =
								Array.from(uniqueNR);
							dataMap["Not Reported"][y]["ids"] =
								Array.from(uniqueNRIDS);
						}
					});
				});
			} else {
				Object.keys(dataMap).forEach((x) => {
					Object.keys(dataMap[x]).forEach((y) => {
						// Create a set to store unique titles
						const uniqueTitles = new Set();
						const uniqueIDS = new Set();
						dataRaw.forEach((item) => {
							if (
								item[selectedX].split(";").includes(x) &&
								item[selectedY].split(";").includes(y)
							) {
								uniqueTitles.add(item.title);
								uniqueIDS.add(item.covidence);
							}
						});
						dataMap[x][y]["titles"] = Array.from(uniqueTitles);
						dataMap[x][y]["ids"] = Array.from(uniqueIDS);
					});
				});
			}
			const xToRemove = [];
			const updatedXLabels = axisLabels.x.filter((x) => {
				// Check if x exists in primaryFiltersSelected['Biofield Modality']
				if (selectedX === "intervention_bucket") {
					if (
						!primaryFiltersSelected["Biofield Modality"].includes(x)
					) {
						xToRemove.push(x);
						return false; // Filter out x from the updatedXLabels
					}
				} else if (selectedX === "condition_category") {
					if (
						!primaryFiltersSelected["Condition Category"].includes(
							x
						)
					) {
						xToRemove.push(x);
						return false; // Filter out x from the updatedXLabels
					}
				} else if (selectedX === "direction_of_results") {
					if (
						!primaryFiltersSelected[
							"Direction of Results"
						].includes(x)
					) {
						xToRemove.push(x);
						return false; // Filter out x from the updatedXLabels
					}
				}

				// Check if all corresponding y values have empty ids
				const isEmpty = axisLabels.y.every((y) => {
					return dataMap[x][y].ids.length === 0;
				});

				if (isEmpty) {
					xToRemove.push(x);
					return false; // Filter out x from the updatedXLabels
				}

				return true; // Keep x in the updatedXLabels
			});

			const yToRemove = [];
			const updatedYLabels = axisLabels.y.filter((y) => {
				// Check if y exists in primaryFiltersSelected['Biofield Modality']

				if (selectedY === "intervention_bucket") {
					if (
						!primaryFiltersSelected["Biofield Modality"].includes(y)
					) {
						yToRemove.push(y);
						return false; // Filter out y from the updatedXLabels
					}
				} else if (selectedY === "condition_category") {
					if (
						!primaryFiltersSelected["Condition Category"].includes(
							y
						)
					) {
						yToRemove.push(y);
						return false; // Filter out y from the updatedXLabels
					}
				} else if (selectedY === "outcome_category") {
					if (
						!advancedFiltersSelected["Outcome Category"].includes(y)
					) {
						yToRemove.push(y);
						return false; // Filter out y from the updatedXLabels
					}
				}

				// Check if all corresponding y values have empty ids
				const isEmpty = axisLabels.x.every((x) => {
					return dataMap[x][y].ids.length === 0;
				});

				if (isEmpty) {
					yToRemove.push(y);
					return false; // Filter out y from the updatedXLabels
				}

				return true; // Keep y in the updatedXLabels
			});
			setAxisToSend({
				x: updatedXLabels,
				y: updatedYLabels,
			});
			setShouldRenderTable(true);
			return dataMap;
		};
		if (dataRaw !== undefined && axisLabels) {
			setCleanData(transformData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataRaw, axisLabels]);

	useEffect(() => {
		axios({
			url: "/get_primary_filters/",
		})
			.then((response) => {
				setPrimaryFilters(
					Object.fromEntries(Object.entries(response.data))
				);
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
		axios({
			url: "/get_advanced_filters/",
		})
			.then((response) => {
				setAdvancedFilters(
					Object.fromEntries(Object.entries(response.data))
				);
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
	}, []);

	useEffect(() => {
		if (
			primaryFilters &&
			!Object.keys(sessionStorage).includes("primaryFiltersSelected")
		) {
			setPrimaryFiltersSelected(primaryFilters);
			const initialCheckAllState = {};
			Object.keys(primaryFilters).forEach((primaryFilterName) => {
				initialCheckAllState[primaryFilterName] = true;
			});
			setIsCheckAllPrimaryFilters(initialCheckAllState);
		}
	}, [primaryFilters]);

	useEffect(() => {
		if (
			advancedFilters &&
			!Object.keys(sessionStorage).includes("advancedFiltersSelected")
		) {
			setAdvancedFiltersSelected(advancedFilters);
			const initialCheckAllState = {};
			Object.keys(advancedFilters).forEach((advancedFilterName) => {
				initialCheckAllState[advancedFilterName] = true;
			});
			setIsCheckAllAdvancedFilters(initialCheckAllState);
		}
	}, [advancedFilters]);

	useEffect(() => {
		if (modalIsOpen) {
			document.body.classList.add("modal-open");
		} else {
			document.body.classList.remove("modal-open");
		}
	}, [modalIsOpen]);

	const handleShowReports = (xaxis, yaxis, result) => {
		const reports = cleanData[xaxis][yaxis]["titles"];
		const reportInfo = [];
		reports.forEach((reportName) => {
			const foundReport = dataRaw.find((report) => {
				if (result === "Others") {
					return (
						report.title === reportName &&
						(report.direction_of_results === ";Not Significant;" ||
							report.direction_of_results === ";NS;")
					);
				} else if (result === "NR") {
					return (
						report.title === reportName &&
						report.direction_of_results === ";Not Reported;"
					);
				}
				// else if (result === "Negative") {
				// 	return (
				// 		report.title === reportName &&
				// 		(report.direction_of_results === ";Negative;" ||
				// 			report.direction_of_results === ";NS;")
				// 	);
				// }
				return (
					report.title === reportName &&
					report.direction_of_results === ";" + result + ";"
				);
			});
			if (foundReport) {
				const id = foundReport.id;
				const covidence = foundReport.covidence;
				const authors = foundReport.authors;
				const abstract = foundReport.abstract;
				const title = foundReport.title;
				const year = foundReport.year;
				const link = foundReport.link;
				const citation = foundReport.citation;
				const journal = foundReport.journal;
				reportInfo.push({
					id: id,
					covidence: covidence,
					authors: authors,
					title: title,
					abstract: abstract,
					year: year,
					link: link,
					citation: citation,
					journal: journal,
				});
			}
		});
		setCurrentReports(reportInfo);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		setCurrentReports([]);
	};

	const filtersClearAll = () => {
		setPrimaryFiltersSelected(primaryFilters);

		const isCheckAllPrimaryFiltersTmp = isCheckAllPrimaryFilters;
		Object.keys(primaryFilters).forEach((filterName) => {
			isCheckAllPrimaryFiltersTmp[filterName] = true;
		});
		setIsCheckAllPrimaryFilters(isCheckAllPrimaryFiltersTmp);

		setAdvancedFiltersSelected(advancedFilters);
		const isCheckAllAdvancedFiltersTmp = isCheckAllAdvancedFilters;
		Object.keys(advancedFilters).forEach((filterName) => {
			isCheckAllAdvancedFiltersTmp[filterName] = true;
		});
		setIsCheckAllAdvancedFilters(isCheckAllAdvancedFiltersTmp);
		setSearchTerm("");
	};

	useEffect(() => {
		setFiltersLoading(false);
		if (typeof primaryFiltersSelected !== "undefined") {
			sessionStorage.setItem(
				"primaryFiltersSelected",
				JSON.stringify(primaryFiltersSelected)
			);
		}
		if (typeof isCheckAllPrimaryFilters !== "undefined") {
			sessionStorage.setItem(
				"isCheckAllPrimaryFilters",
				JSON.stringify(isCheckAllPrimaryFilters)
			);
		}
		if (typeof advancedFiltersSelected !== "undefined") {
			sessionStorage.setItem(
				"advancedFiltersSelected",
				JSON.stringify(advancedFiltersSelected)
			);
		}
		if (typeof isCheckAllAdvancedFilters !== "undefined") {
			sessionStorage.setItem(
				"isCheckAllAdvancedFilters",
				JSON.stringify(isCheckAllAdvancedFilters)
			);
		}
		if (searchTerm !== null) {
			sessionStorage.setItem("searchTerm", searchTerm);
		}
		axios({
			method: "POST",
			url: "/filter_send_data/",
			data: {
				filters: {
					...primaryFiltersSelected,
					...advancedFiltersSelected,
				},
				searchTerm: searchTerm,
			},
		})
			.then((response) => {
				setDataRaw(response.data);
				setFiltersLoading(true);
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					console.log(error.response.status);
					console.log(error.response.headers);
				}
			});
	}, [
		primaryFiltersSelected,
		isCheckAllPrimaryFilters,
		advancedFiltersSelected,
		isCheckAllAdvancedFilters,
		searchTerm,
	]);

	return (
		<div id="app-wrapper">
			<Header />
			<IntroTextHome />
			{typeof dataRaw !== "undefined" && (
				<div
					className="container search-bar-container"
					style={{ marginBottom: "5px" }}
				>
					<SearchBar
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
					/>
				</div>
			)}
			<div
				className="container primary-filters-container"
				style={{ height: "auto" }}
			>
				<Typography
					variant="body1"
					style={{ margin: "16px 0px 4px 5px", fontWeight: "bold" }}
				>
					Filters:
				</Typography>
				{primaryFilters && primaryFiltersSelected && (
					<Filters
						filters={primaryFilters}
						filtersSelected={primaryFiltersSelected}
						isCheckAllFilters={isCheckAllPrimaryFilters}
						setFiltersSelected={setPrimaryFiltersSelected}
						setIsCheckAllFilters={setIsCheckAllPrimaryFilters}
						showClearAllButton={true}
						filtersClearAll={filtersClearAll}
					/>
				)}
			</div>

			<div className="container">
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						onClick={toggleAdvancedFilters}
					>
						<Typography>Use advanced filters</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<div className="container advanced-filters-container">
							{advancedFilters && advancedFiltersSelected && (
								<Filters
									filters={advancedFilters}
									filtersSelected={advancedFiltersSelected}
									isCheckAllFilters={
										isCheckAllAdvancedFilters
									}
									setFiltersSelected={
										setAdvancedFiltersSelected
									}
									setIsCheckAllFilters={
										setIsCheckAllAdvancedFilters
									}
									showClearAllButton={false}
									filtersClearAll={filtersClearAll}
								/>
							)}
						</div>
					</AccordionDetails>
				</Accordion>
			</div>

			{axisLabels && cleanData ? (
				<div className="container">
					<ReportsModal
						isOpen={modalIsOpen}
						onClose={closeModal}
						reports={currentReports}
					/>
					<TableTopContainer
						setSelectedX={setSelectedX}
						setSelectedY={setSelectedY}
						selectedAxis={selectedAxis}
						setSelectedAxis={setSelectedAxis}
						axisCombination={axisCombination}
						toggleHorizontalScrolling={toggleHorizontalScrolling}
						setToggleHorizontalScrolling={
							setToggleHorizontalScrolling
						}
					/>

					{/*  && searchBarLoading  add that if we want loading spinner when search*/}
					{shouldRenderTable && filtersLoading ? (
						<>
							<ReportsTable
								xAxisLabels={axisToSend.x}
								yAxisLabels={axisToSend.y}
								xAxisTitle={selectedX}
								yAxisTitle={selectedY}
								cleanData={cleanData}
								handleShowReports={handleShowReports}
								papersResult={paperResult}
								toggleHorizontalScrolling={
									toggleHorizontalScrolling
								}
							/>
						</>
					) : null}

					{!shouldRenderTable && <LoadingSpinner />}
					{!filtersLoading && <LoadingSpinner />}
					{/* Add this if we want loading spinner when search
					{!searchBarLoading && <LoadingSpinner />} */}
					<span id="footnote">
						<span>*</span>
						<span>In the evidence map, studies may appear multiple times if they involve comparisons or combinations of different biofield therapies. For more details, visit the <a style={{textDecoration:"underline"}} href="/about#multiple-articles">How to Use</a> page.</span>
					</span>
					<Bottom />
				</div>
			) : (
				<LoadingSpinner />
			)}
			{/* <Footer /> */}
		</div>
	);
}

export default Home;
