import { Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "../pages/Home";
import Methods from "../pages/Methods";
import About from "../pages/About";
import Report from "../pages/Report";
import Visit from "../pages/Visit";
import "../css/bootstrap/bootstrap.min.css";
import "../css/bootstrap/bootstrap-select.min.css";
import icon from "../images/favicon.ico";

function App() {
	useEffect(() => {
		const favicon = document.getElementById("favicon");
		favicon.setAttribute("href", icon);
	}, []);

	useEffect(() => {
		const clear1 = JSON.parse(
			sessionStorage.getItem("primaryFiltersSelected")
		);
		if (clear1 && clear1["Outcome Category"]) {
			sessionStorage.removeItem("advancedFiltersSelected");
			sessionStorage.removeItem("primaryFiltersSelected");
			sessionStorage.removeItem("isCheckAllAdvancedFilters");
			sessionStorage.removeItem("isCheckAllPrimaryFilters");
		}
	}, []);
	return (
		<div>
			<Routes>
				<Route path="*" element={<Home />} />
				<Route path="/methods" element={<Methods />} />
				<Route path="/about" element={<About />} />
				<Route path="/report/:reportId" element={<Report />} />
				<Route path="/visit" element={<Visit />} />
			</Routes>
		</div>
	);
}

export default App;
