import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import "./css/style.css";
if (
	process.env.NODE_ENV !== "development" &&
	!window.location.hostname !== "localhost" &&
	!window.location.href.includes(":8000")
) {
	if (
		!window.location.href.includes("biofieldsciencemap.org") ||
		window.location.protocol !== "https:"
	) {
		window.location.replace("https://www.biofieldsciencemap.org/");
	}
}
const THEME = createTheme({
	typography: {
		fontFamily: "Helvetica",
	},
	palette: {
		primary: {
			main: "#6D8FAA",
		},
	},
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
	<ThemeProvider theme={THEME}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ThemeProvider>
);
