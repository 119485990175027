import React from "react";

function IntroTextHome() {
	return (
		<div className="container" style={{ marginBottom: "3rem" }}>
			<p>
				Welcome to the Biofield Science Evidence Map! This interactive
				public resource offers a comprehensive view of the clinical
				research conducted on biofield therapies published to date in
				peer-reviewed journals.
			</p>
			<p>
				This first-of-its-kind tool is intended to support researchers,
				practitioners, patients, healthcare professionals, policy-makers
				and funders in searching and exploring the extensive body of
				scientific evidence in this field. Click through to view study
				summaries and publication links. The Evidence Map is based on a
				thorough scoping review process outlined in the{" "}
				<a href="/methods" className="custom-link">
					Methodology
				</a>{" "}
				tab. Learn more about how to use the map{" "}
				<a href="/about#how_to_use" className="custom-link">
					here
				</a>
				.
			</p>
		</div>
	);
}

export default IntroTextHome;
